<template>
  <div class="container" id="CategoriesProducts">
    <div
        class="header"
        :style="
        'background-color: ' +
          color +
          '; background-image:url(' +
          window.Routes.getImage(image, 'preview', 'none') +
          ')'
      "
    >
      <div class="title font-size-xl font-weight-bold">
        {{ title }}
      </div>
      <div class="description font-size-md color-secondary">
        {{ subtitle }}
      </div>

      <div class="skeleton" v-if="title == null && subtitle == null">
        <div class="line skeleton_loader dark thick w-40"></div>
        <div class="line skeleton_loader dark w-70"></div>
      </div>
    </div>

    <filter-view @resetFilters="resetFilters" ref="filtersView">
      <template v-slot:filters>
        <products-section-filters
            :defaults="section_filters"
            @saved="savedFilters"
            ref="filters"
        />
      </template>
      <template v-slot:body>
        <!-- PRODUCT-SECTIONS Skeleton Loader -->
        <div v-if="!productSectionLoaded">
          <div class="row mb-5">
            <div
                class="col-6 col-lg-4 col-xl-3 skeleton mb-3"
                v-for="index in 12"
                :key="index"
            >
              <div class="ratio ratio-4_3 bg-gray"></div>
              <div class="line skeleton_loader"></div>
              <div class="line skeleton_loader w-50"></div>
              <div class="line skeleton_loader dark w-30 thick"></div>
            </div>
          </div>
        </div>
        <div class="row mb-5" v-if="productSectionLoaded">
          <product-card
              class="col-6 col-lg-4 col-xl-3"
              v-for="product in products"
              :product="product"
              :key="product.id"
          />
        </div>

        <loading :loading="!loading_ready"/>
      </template>
    </filter-view>
  </div>
</template>

<script>
//TODO: CHECK IF NO PRODUCTS FOUND
import {ref} from "vue";
import ProductsSectionFilters from "@/components/ProductsSectionFilters.vue";
import ProductCard from "@/components/ProductCard.vue";
import Loading from "@/components/Loading.vue";
import FilterView from "@/components/FilterView.vue";
import {mapActions} from "vuex";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";
import {useRoute} from "vue-router";

export default {
  setup() {
    const filters = ref(null);
    const filtersView = ref(null);

    const route = useRoute();
    const {t} = useI18n() // use as global scope
    useMeta({
      title: route.query.title ?? t("categories.show.page_title"),
    })

    return {
      filters,
      filtersView
    };
  },
  components: {ProductsSectionFilters, ProductCard, Loading, FilterView},
  name: "CategoriesProducts",
  data() {
    return {
      title: null,
      subtitle: null,
      color: null,
      image: null,
      current_page: 1,
      last_page: 1,
      pagination: 16,
      products: [],
      productSectionLoaded: false,
      loading_ready: true,
      section_filters: {
        sort: []
      }
    };
  },
  computed: {
    category_type: function () {
      return this.$route.params.type != undefined ? "packed" : "preference";
    },
    category_id: function () {
      if (this.$route.params.type == "packed") {
        return this.$route.params.id;
      }
      return parseInt(this.$route.params.id);
    }
  },
  watch: {
    ...mapActions(["setErrors"]),
    category_id: function () {
      if (!isNaN(this.category_id)) {
        //Reset Placeholders
        this.productSectionLoaded = false;
        this.title = null;
        this.subtitle = null;
        this.color = null;
        this.image = null;

        //Get new data
        this.checkStorage();
      }
    }
  },
  methods: {
    openFilters() {
      this.filtersShown = true;
    },
    closeFilters() {
      this.filtersShown = false;
    },
    async getProducts(form_data = {}, page = 1) {
      form_data["section_type"] = this.category_type;
      form_data["section_id"] = this.category_id;
      form_data["pagination"] = this.pagination;

      return new Promise((resolve, reject) => {

        let url =
            window.Routes.getRoute("homepageProductSectionsShow");
        window.axios.post(url, form_data, {
          params: prepareGuestAddressParams({
            'page': page
          }),
        }).then(
            response => {
              this.current_page = response.data.meta.current_page;
              this.last_page = response.data.meta.last_page;
              resolve(response.data);
            },
            error => {
              let errors = error.response.data.errors;
              this.setErrors(errors);
              reject(errors.data.errors);
            }
        );
      });
    },
    prepareData(data) {
      let formData = JSON.parse(JSON.stringify(data));
      if (formData["sort_by_price"] == undefined) {
        formData["sort_by_price"] = [];
      } else {
        formData["sort_by_price"] = [formData["sort_by_price"]];
      }
      let sort_by = [...formData["sort"], ...formData["sort_by_price"]];
      formData["sort_by"] = sort_by;
      return formData;
    },
    resetFilters() {
      this.section_filters = {
        payment_method: "both",
        sort: [],
        radius: 15
      };

      this.formData = this.prepareData(this.section_filters);
    },
    savedFilters(filters) {
      this.productSectionLoaded = false;
      // this.synced_filters = data;
      //Save to the local storage
      localStorage.setItem("filters", JSON.stringify(filters));

      this.formData = this.prepareData(filters);
      //GET PRODUCTS
      this.getProducts(this.formData).then(response => {
        this.productSectionLoaded = true;
        this.products = response.data;
      });

      this.filtersView.closeFilters();
    },
    checkStorage() {
      //Get Filters from local storage
      let synced_filters_storage = JSON.parse(localStorage.getItem("filters"));

      if (synced_filters_storage != undefined) {
        this.section_filters = synced_filters_storage;
      } else {
        this.section_filters = {
          payment_method: "both",
          sort: [],
          radius: 15
        };
      }

      // PREPARE DATA FOR API
      this.formData = this.prepareData(this.section_filters);

      //GET PRODUCTS
      this.getProducts(this.formData).then(response => {
        this.productSectionLoaded = true;
        this.products = response.data;
        this.title = response.meta.title;
        this.subtitle = response.meta.subtitle;
        this.color = response.meta.color;
        this.image = response.meta.image;
      });
    },
    loadMore() {
      if (this.loading_ready == true) {
        if (
            this.current_page < this.last_page &&
            this.productSectionLoaded == true
        ) {
          if (
              window.innerHeight + window.scrollY >=
              document.body.scrollHeight
          ) {
            //DISPLAY LOADING BAR and disable scroll bottom event
            this.loading_ready = false;
            //LOAD MORE PRODUCTS
            this.getProducts(this.formData, this.current_page + 1).then(
                response => {
                  //HIDE LOADING BAR and enable scroll bottom event
                  this.loading_ready = true;
                  this.products = [...this.products, ...response.data];
                }
            );
          }
        }
      }
    }
  },
  created() {
    this.checkStorage();
  },
  mounted() {
    window.onscroll = () => {
      this.loadMore();
    };
  }
};
</script>

<style lang="scss" scoped>
#CategoriesProducts {
  & > .header {
    width: 100%;
    height: auto;
    background-color: map-get($colors, "lightGray");
    border-bottom-left-radius: map-get($defaults, "border-radius");
    border-bottom-right-radius: map-get($defaults, "border-radius");
    padding: (map-get($spacings, "large") * 2) map-get($spacings, "large");
    background-size: auto 100%;
    background-position: center right;
    background-repeat: no-repeat;
  }
}

@include media-breakpoint-down(md) {
  #CategoriesProducts {
    & > .header {
      padding: map-get($spacings, "medium") map-get($spacings, "medium");
      padding-right: 40%;
    }
  }
}
</style>
